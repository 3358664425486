import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import Layout from '../components/Layout'

const containerStyle = {
  width: 'auto',
  height: '100%'
};
const icon = {
  url: "/img/logMap.png",
  scaledSize: {width: 50, height: 50}
}
export const ContactPageTemplate = ({
  mapPoint,
  nearPlaces,
  bottomTitle,
  bottomText,
}) => (
  <div id="contact">
    <section id="location" className="backgroundSectionGray">
      <Container fluid="lg">
        <div className="title"><h1>Ubicación</h1></div>
        <Row>
          <Col md="5" xs="12" className="places">
            {nearPlaces.map(place => {
              return (
                <Row>
                  <Col md={2}><img src={place.image} /></Col>
                  <Col md={9} className="info">
                    <p>{place.title}</p>
                    <div className="times">
                    {place.times.map(time => {
                      return(
                        <div className="time"><span className={'fa '+time.icon}/>{time.time}</div>
                      )
                    })}</div></Col>
                </Row>
              )
            })}
          </Col>
          <Col md="6" xs="12" className="map">
            <LoadScript googleMapsApiKey="AIzaSyDxrWLrtZ1y_pHOyOZpfIPVmxI5DcMm-X8">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapPoint}
                zoom={17}>
                  <Marker position={mapPoint} icon={icon} />
              </GoogleMap>
              {/* <Img fluid={staticMap} alt={mapPoint} /> */}
            </LoadScript> 
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{bottomTitle}</h2>
          </Col>
          <Col>
            <p>{bottomText}</p>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
)

ContactPageTemplate.propTypes = {
  nearPlaces: PropTypes.array,
  mapPoint: PropTypes.string,
  bottomTitle: PropTypes.string,
  bottomText: PropTypes.string,
  staticMap: PropTypes.object
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const point = JSON.parse(frontmatter.map_point)
  const center = {lng: point.coordinates[0], lat: point.coordinates[1]}

  return (
    <Layout currentSlug={frontmatter.titleC} title={frontmatter.titleC} description={frontmatter.descriptionC}>
      <ContactPageTemplate
        nearPlaces={frontmatter.near_places}
        mapPoint={center}
        bottomTitle={frontmatter.bottom_title}
        bottomText={frontmatter.bottom_text}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        titleC
        descriptionC
        near_places {
          image
          title
          times {
            icon
            time
          }
        }
        map_point
        bottom_title
        bottom_text
      }
    }
  }
`